ul.events

  padding: 0
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  list-style-type: none
  & > li
    position: relative
    display: flex
    flex-direction: column
    padding: 0
    height: auto
    margin: 10px 0px
    width: 32%
    border-radius: 2px
    cursor: pointer
    background-color: #3b3b3b
    font-size: 15px
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px
    a
      text-decoration: none
      display: flex
      flex-direction: column
      &:hover
        background-color: #3b3b3b
      img
        width: 100%
    .event-title
      color: white
      padding: 5px 10px
      font-weight: 600
    .event-description
      font-size: 13px
      padding: 5px 10px
      overflow: auto
      color: rgba(255, 255, 255, 0.8)
      & h1,h2,h3,h4,h5,h6
        margin: 3px 0
    .event-duedate
      display: flex
      flex-direction: row-reverse
      width: 100%
      font-size: 13px
      padding: 5px 10px
      color: rgba(255, 255, 255, 0.8)
